.location-landing-page {
  padding-top: 2rem;
}

.location-hero {
  position: relative;
  min-height: 60vh;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 4rem 2rem;
}

.location-hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.hero-description {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.primary-button, .secondary-button {
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.primary-button {
  background-color: #4CAF50;
  color: white;
}

.secondary-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.primary-button:hover {
  background-color: #45a049;
}

.secondary-button:hover {
  background-color: white;
  color: #1e3c72;
}

.location-services {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card h3 {
  color: #1e3c72;
  margin-bottom: 1rem;
}

.service-features {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.service-features li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.service-features li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4CAF50;
}

.location-testimonials {
  padding: 4rem 0;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rating {
  color: #ffd700;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.testimonial-author strong {
  color: #1e3c72;
}

.testimonial-author span {
  color: #666;
  font-size: 0.9rem;
}

.location-contact {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 2rem;
}

.contact-info h3 {
  color: #1e3c72;
  margin-bottom: 1rem;
}

.contact-details {
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
}

.contact-details li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contact-details a {
  color: #1e3c72;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-details a:hover {
  color: #4CAF50;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: #1e3c72;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #152a4f;
}

@media (max-width: 768px) {
  .location-hero h1 {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .service-card,
  .testimonial-card {
    padding: 1.5rem;
  }
} 