/* Base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.mt {
  margin-top: 10px;
}

.bold {
  font-weight: 600;
}

/* Header styles */
.header {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .header-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.logo {
  text-align: center;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .logo {
    text-align: left;
    margin-bottom: 0;
  }
}

.logo h1 {
  color: #1e3a8a;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
}

.logo p {
  font-size: 0.875rem;
  color: #666;
}

.main-nav ul {
  display: flex;
  gap: 1.5rem;
  font-size: 0.875rem;
}

.main-nav a:hover {
  color: #1d4ed8;
}

.main-nav .active a {
  font-weight: 500;
}

/* Main content styles */
.main {
  padding: 3rem 0;
}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.section-header h2 {
  color: #1e3a8a;
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section-header p {
  max-width: 42rem;
  margin: 0 auto;
  color: #666;
}

/* Contact cards */
.contact-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .contact-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .contact-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.contact-card {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.contact-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-card h3 {
  color: #1e3a8a;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.phone-number {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.email,
.card-details,
.address,
.hours {
  font-size: 0.875rem;
}

.card-details,
.emergency {
  color: #666;
}

/* Areas section */
.areas-section {
  margin-bottom: 4rem;
}

.areas-section h2 {
  color: #1e3a8a;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.areas-grid {
  max-width: 32rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem 2rem;
  text-align: center;
}

.areas-grid div {
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.375rem;
  border: 1px solid #e5e5e5;
  transition: transform 0.2s ease;
}

.areas-grid div:hover {
  transform: translateY(-2px);
}

/* WhatsApp button */
.whatsapp-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #25d366;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.whatsapp-button:hover {
  background-color: #128c7e;
}

/* Utility classes for form */
.max-w-3xl {
  max-width: 48rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-16 {
  margin-bottom: 4rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.grid {
  display: grid;
}

.gap-6 {
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.w-full {
  width: 100%;
}

.block {
  display: block;
}

.rounded {
  border-radius: 0.375rem;
}

.transition {
  transition-property: background-color, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

/* Form section styles */
.form-section {
  max-width: 48rem;
  margin: 0 auto 4rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-section h2 {
  color: #1e3a8a;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.form-section p {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  width: 100%;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #1e40af;
  box-shadow: 0 0 0 3px rgba(30, 64, 175, 0.1);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background-color: #1e3a8a;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.submit-button:hover {
  background-color: #1e40af;
} 