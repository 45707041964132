.about-us {
  padding: 2rem 0;
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

/* Contact Info Section */
.contact-info {
  margin-bottom: 4rem;
}

.contact-info h1 {
  text-align: center;
  color: #1e3c72;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.intro {
  text-align: center;
  color: #666;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Contact Cards */
.contact-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.contact-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.contact-card h2, .contact-card h3 {
  color: #1e3c72;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.phone-number {
  display: block;
  font-size: 1.5rem;
  color: #1e3c72;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 0.5rem;
  transition: color 0.2s ease;
}

.phone-number:hover {
  color: #2a5298;
}

.highlight {
  color: #2a5298;
  font-weight: 500;
}

/* Contact Form Section */
.contact-form {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 4rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%;
}

.contact-form h2 {
  text-align: center;
  color: #1e3c72;
  font-size: 2.8rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.contact-form > p {
  text-align: center;
  color: #666;
  margin-bottom: 3rem;
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.form-group label {
  margin-bottom: 0.75rem;
  color: #333;
  font-weight: 500;
  font-size: 1rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: #fff;
  width: 100%;
}

.form-group textarea {
  min-height: 200px;
  resize: vertical;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #1e3c72;
  box-shadow: 0 0 0 2px rgba(30, 60, 114, 0.1);
}

.submit-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 2rem auto 0;
  padding: 1rem 1.5rem;
  background: #1e3c72;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background: #2a5298;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(42, 82, 152, 0.2);
}

/* WhatsApp Button */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #25D366;
  color: white;
  padding: 12px 24px;
  border-radius: 24px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 250px;
  box-shadow: 0 2px 8px rgba(37, 211, 102, 0.3);
  z-index: 999999;
}

.whatsapp-button:hover {
  background-color: #128C7E;
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 4px 12px rgba(37, 211, 102, 0.4);
}

/* Areas We Serve */
.areas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.5rem;
  text-align: center;
}

.area {
  padding: 1rem;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.area:hover {
  transform: translateY(-2px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-form {
    padding: 2rem;
    margin: 1rem;
  }

  .contact-form h2 {
    font-size: 2rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 0.9rem;
  }

  .submit-button {
    margin-top: 1.5rem;
  }

  .whatsapp-button {
    max-width: calc(100% - 2rem);
    margin: 0 1rem;
  }
} 