.quote-calculator {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.property-type-section {
  margin-bottom: 30px;
}

.property-type-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.property-type-card {
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
}

.property-type-card:hover {
  border-color: #3b82f6;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.property-type-card.selected {
  border-color: #3b82f6;
  background-color: #eff6ff;
}

.property-type-image {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 10px;
}

.property-type-card h3 {
  font-size: 1.1rem;
  margin: 0;
  color: #1f2937;
}

.property-type-card p {
  font-size: 0.9rem;
  color: #6b7280;
  margin: 5px 0 0;
}

.room-section {
  margin-bottom: 30px;
}

.room-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.room-card {
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
}

.room-card:hover {
  border-color: #3b82f6;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.room-card img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 10px;
}

.room-card span {
  font-size: 0.9rem;
  color: #374151;
}

.selected-rooms {
  margin-top: 20px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
}

.selected-rooms-header {
  padding: 15px;
  background: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.selected-rooms-list {
  padding: 15px;
}

.selected-room-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
}

.selected-room-item:last-child {
  border-bottom: none;
}

.room-size-select {
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  margin-right: 10px;
}

.remove-room-btn {
  color: #ef4444;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.remove-room-btn:hover {
  color: #dc2626;
}

.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .contact-form {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0 15px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-group input {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px; /* Prevents iOS zoom on focus */
    padding: 12px;
  }

  .navigation-buttons {
    padding: 0 15px;
    margin-top: 1.5rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 8px;
  color: #374151;
}

.form-group input {
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 1rem;
}

.form-group input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 1rem;
  gap: 1rem;
}

.secondary-button, 
.primary-button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  min-width: 120px;
}

.secondary-button {
  background-color: #e2e8f0;
  color: #4a5568;
}

.secondary-button:hover {
  background-color: #cbd5e1;
  transform: translateY(-2px);
}

.primary-button {
  background-color: #3b82f6;
  color: white;
}

.primary-button:hover:not(:disabled) {
  background-color: #2563eb;
  transform: translateY(-2px);
}

.primary-button:disabled {
  background-color: #cbd5e1;
  cursor: not-allowed;
  transform: none;
}

/* Remove unused button classes */
.next-button,
.back-button {
  display: none;
}

.quote-result {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.quote-header {
  margin-bottom: 20px;
}

.quote-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.unit-card {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 15px;
}

.unit-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 15px;
}

.unit-info h4 {
  margin: 0 0 10px;
  color: #1f2937;
}

.unit-specs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 15px 0;
}

.unit-spec {
  font-size: 0.9rem;
}

.unit-spec span {
  color: #6b7280;
}

.price-breakdown {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e7eb;
}

.price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total-price {
  margin-top: 20px;
  padding: 15px;
  background: #eff6ff;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-price .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e40af;
}

.installation-info {
  margin-top: 2rem;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  text-align: center;
}

.installation-info h3 {
  color: #1e293b;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.installation-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  justify-items: center;
}

.installation-info li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
}

.installation-info li strong {
  color: #1e293b;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.installation-info li span {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.4;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  color: #6b7280;
}

.step.active .step-number {
  background: #3b82f6;
  color: white;
}

.step.completed .step-number {
  background: #10b981;
  color: white;
}

.step-label {
  font-size: 0.9rem;
  color: #6b7280;
}

.step.active .step-label {
  color: #3b82f6;
  font-weight: 600;
}

.step-line {
  position: absolute;
  top: 15px;
  left: 50px;
  right: 50px;
  height: 2px;
  background: #e5e7eb;
  z-index: 0;
}

.step-progress {
  position: absolute;
  top: 15px;
  left: 50px;
  height: 2px;
  background: #3b82f6;
  transition: width 0.3s ease;
  z-index: 0;
}

/* Cost Estimator */
.cost-estimator {
  background: #f0f9ff;
  border: 1px solid #bae6fd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.cost-estimator h3 {
  color: #0369a1;
  margin: 0 0 10px;
}

.estimate-range {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 1.5rem;
  color: #0c4a6e;
}

.estimate-range span:nth-child(2) {
  font-size: 1rem;
  color: #64748b;
}

.estimate-note {
  margin: 10px 0 0;
  font-size: 0.8rem;
  color: #64748b;
}

/* Tooltips */
.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #1e293b;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  width: max-content;
  max-width: 250px;
  z-index: 10;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #1e293b transparent transparent transparent;
}

.tooltip-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  vertical-align: middle;
}

.size-select-container {
  position: relative;
  display: inline-block;
}

.size-tooltip {
  bottom: calc(100% + 5px);
}

/* System Type Selection Styles */
.system-type-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.system-type-section h1 {
  text-align: center;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.system-type-section p {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.system-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.system-option-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.system-option-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.system-option-card.selected {
  border-color: #10b981;
  box-shadow: 0 4px 6px rgba(16, 185, 129, 0.1);
}

.system-option-card.premium {
  border: 2px solid #f59e0b;
}

.system-option-card.premium.selected {
  border-color: #f59e0b;
  box-shadow: 0 4px 6px rgba(245, 158, 11, 0.1);
}

.system-option-card h3 {
  color: #1a1a1a;
  margin: 0;
  font-size: 1.5rem;
}

.system-option-card h4 {
  color: #1a1a1a;
  margin: 0.5rem 0;
  font-size: 1.25rem;
}

.system-option-card h5 {
  color: #4b5563;
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.unit-preview {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.unit-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
}

.indoor-unit,
.outdoor-unit {
  text-align: center;
}

.indoor-unit h4,
.outdoor-unit h4 {
  margin-bottom: 10px;
  color: #2c3e50;
  font-size: 1rem;
}

.unit-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.unit-image:hover {
  transform: scale(1.05);
}

.unit-specs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin: 15px 0;
}

.unit-spec {
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.unit-spec span {
  font-weight: 600;
  color: #2c3e50;
  margin-right: 5px;
}

.unit-details {
  margin: 20px 0;
}

.unit-details h4 {
  color: #1e293b;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.specs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin: 15px 0;
}

.spec {
  background: #f8fafc;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.spec span {
  display: block;
  color: #64748b;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.features {
  margin: 20px 0;
}

.features h5 {
  color: #1e293b;
  margin-bottom: 10px;
}

.features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features li {
  padding: 8px 0;
  border-bottom: 1px solid #e2e8f0;
  color: #64748b;
}

.features li:last-child {
  border-bottom: none;
}

.price {
  margin: 20px 0;
  padding: 15px;
  background: #f8fafc;
  border-radius: 4px;
  text-align: center;
}

.price span {
  display: block;
}

.price .amount {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e293b;
  margin-top: 5px;
}

.select-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background: #e2e8f0;
  color: #64748b;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-button:hover {
  background: #cbd5e1;
}

.select-button.selected {
  background: #10b981;
  color: white;
}

.system-option-card.premium .select-button.selected {
  background: #f59e0b;
}

/* Multi-system options styles */
.multi-system-options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.outdoor-unit-info {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.outdoor-unit-info h3 {
  color: #1a1a1a;
  margin: 0 0 1rem;
  font-size: 1.5rem;
}

.indoor-units {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.room-options {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.room-options h4 {
  color: #1a1a1a;
  margin: 0 0 1rem;
  font-size: 1.25rem;
}

/* System Type Selection Images */
.system-type-options .system-type-button img {
  max-width: 200px;
  height: auto;
  object-fit: contain;
  margin-bottom: 1rem;
}

/* Quote Result Images */
.unit-card .unit-image {
  max-width: 180px;
  height: 180px;
  object-fit: contain;
  margin: 1rem;
}

.multi-system-details .outdoor-unit img {
  max-width: 220px;
  height: 220px;
  object-fit: contain;
  margin: 1rem auto;
}

.multi-system-details .indoor-unit img {
  max-width: 160px;
  height: 160px;
  object-fit: contain;
  margin: 0.5rem;
}

/* Ensure images maintain aspect ratio */
.unit-preview img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Container styling for better layout */
.unit-images {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.indoor-unit, .outdoor-unit {
  text-align: center;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem;
}

/* System type selection layout improvements */
.system-type-selection {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.system-type-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.system-type-button {
  background: white;
  padding: 2rem;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.system-type-button:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.system-type-button.active {
  border-color: #3b82f6;
  background-color: #f8fafc;
}

.system-type-button h4 {
  color: #0072CE;
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
}

.system-type-button.active .brand-badge {
  background: #005091;
}

.unit-preview {
  width: 100%;
  margin-bottom: 1.5rem;
}

.indoor-unit {
  text-align: center;
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.indoor-unit h4 {
  color: #4a5568;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.indoor-unit img {
  max-width: 100%;
  height: 140px;
  object-fit: contain;
  margin: 0.5rem 0;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  text-align: left;
  width: 100%;
}

.features-list li {
  padding: 0.5rem 0;
  color: #4a5568;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.features-list li:before {
  content: "✓";
  color: #10b981;
  margin-right: 0.5rem;
  font-weight: bold;
}

.price-note {
  color: #4a5568;
  font-size: 0.9rem;
  margin-top: 1rem;
  text-align: center;
}

/* Toast Notification Styles */
.toast-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4ade80;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

.toast-icon {
  width: 20px;
  height: 20px;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.brand-description {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.brand-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #0072CE;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.brand-badge.premium {
  background: #005091;
}

@media (max-width: 768px) {
  .brand-badge {
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.3rem 0.8rem;
    font-size: 0.8rem;
  }
  
  .brand-description {
    font-size: 1rem;
    margin: 1rem 0.5rem 1.5rem;
  }
} 