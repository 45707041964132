.services-overview {
  padding: 4rem 2rem;
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
}

.services-container h1 {
  text-align: center;
  color: #1e3c72;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.services-intro {
  text-align: center;
  color: #666;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  padding: 1rem;
}

.service-card {
  background: white;
  border-radius: 16px;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.service-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #1e3c72;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.service-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
}

.service-card:hover::after {
  transform: scaleX(1);
}

.service-icon {
  font-size: 3rem;
  margin-bottom: 2rem;
  background: #f8f9fa;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.service-card:hover .service-icon {
  transform: scale(1.1) rotate(5deg);
}

.service-card h2 {
  color: #1e3c72;
  font-size: 1.75rem;
  margin: 0 0 1rem 0;
  font-weight: 700;
  line-height: 1.2;
}

.service-subtitle {
  color: #666;
  font-size: 1.1rem;
  margin: 0 0 2rem 0;
  line-height: 1.6;
}

.service-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  width: 100%;
}

.service-features li {
  padding: 0.75rem 0;
  color: #333;
  display: flex;
  align-items: center;
  line-height: 1.5;
  font-size: 1.05rem;
}

.service-features li::before {
  content: "✓";
  color: #1e3c72;
  margin-right: 1rem;
  font-weight: bold;
  background: #f0f4ff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  flex-shrink: 0;
}

.cta-button {
  background: #1e3c72;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.cta-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  transition: 0.5s;
}

.cta-button:hover {
  background: #2a5298;
  transform: translateY(-2px);
}

.cta-button:hover::after {
  left: 100%;
}

.cta-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(30, 60, 114, 0.3);
}

.features-list, .benefits-list {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.features-list li, .benefits-list li {
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.features-list li:before {
  content: "✓";
  color: #1e3c72;
  position: absolute;
  left: 0;
}

.benefits-list li:before {
  content: "→";
  color: #1e3c72;
  position: absolute;
  left: 0;
}

.benefits-section {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}

.benefits-section h4 {
  color: #1e3c72;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.calculator-section {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #e9ecef;
}

.calculator-section h3 {
  text-align: center;
  color: #1e3c72;
  font-size: 1.75rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .services-overview {
    padding: 3rem 1rem;
  }

  .services-container h1 {
    font-size: 2rem;
  }

  .services-intro {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .service-card {
    padding: 2rem;
  }

  .service-icon {
    font-size: 2.5rem;
    width: 60px;
    height: 60px;
  }

  .service-card h2 {
    font-size: 1.5rem;
  }

  .service-subtitle {
    font-size: 1rem;
  }

  .service-features li {
    font-size: 1rem;
  }

  .cta-button {
    padding: 0.9rem 1.75rem;
    font-size: 1rem;
  }

  .features-list li, .benefits-list li {
    font-size: 0.95rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .service-card,
  .service-icon,
  .cta-button,
  .service-card::after {
    transition: none;
  }
} 