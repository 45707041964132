.terms-and-conditions {
  padding: 2rem 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.terms-and-conditions .container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

.terms-and-conditions h1 {
  font-size: 2.5rem;
  color: #1e3c72;
  margin-bottom: 2rem;
  text-align: center;
}

.terms-and-conditions .content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.terms-and-conditions section {
  margin-bottom: 2rem;
}

.terms-and-conditions section:last-child {
  margin-bottom: 0;
}

.terms-and-conditions h2 {
  font-size: 1.5rem;
  color: #1e3c72;
  margin-bottom: 1rem;
}

.terms-and-conditions p {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.terms-and-conditions p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .terms-and-conditions {
    padding: 1rem 0;
  }

  .terms-and-conditions h1 {
    font-size: 2rem;
  }

  .terms-and-conditions .content {
    padding: 1.5rem;
  }

  .terms-and-conditions h2 {
    font-size: 1.25rem;
  }
} 