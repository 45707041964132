.google-business-profile {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;
}

.profile-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.header-left h2 {
  color: #1a237e;
  font-size: 2rem;
  margin: 0 0 0.5rem 0;
}

.header-left p {
  color: #666;
  margin: 0;
}

.header-center {
  text-align: center;
}

.google-logo {
  height: 40px;
  width: auto;
}

.header-right {
  text-align: right;
}

.rating-summary {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.stars {
  color: #fbbc04;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.rating-text {
  margin: 0.5rem 0;
  font-weight: 500;
  text-align: right;
  line-height: 1.4;
}

.review-count {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.review-card {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.review-stars {
  color: #fbbc04;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.review-date {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.review-text {
  flex-grow: 1;
  font-style: italic;
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

.review-meta {
  border-top: 1px solid #eee;
  padding-top: 1rem;
}

.reviewer-type {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.service-type {
  color: #666;
  font-size: 0.9rem;
}

.view-all-container {
  text-align: center;
}

.view-all-button {
  display: inline-block;
  background: #1a237e;
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.view-all-button:hover {
  background: #0d47a1;
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .profile-header {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 1.5rem;
  }

  .header-right {
    text-align: center;
  }

  .rating-summary {
    align-items: center;
  }

  .reviews-grid {
    grid-template-columns: 1fr;
  }
}

.google-business-profile.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.google-business-profile.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background: #fff5f5;
  border: 1px solid #feb2b2;
  border-radius: 8px;
}

.error-message {
  text-align: center;
  color: #c53030;
}

.loading-spinner {
  color: #4a5568;
  font-size: 1.2rem;
}

.google-business-info {
  margin-bottom: 2rem;
}

.rating-section {
  margin-bottom: 1.5rem;
}

.rating {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.star {
  color: #e2e8f0;
  font-size: 1.25rem;
}

.star.filled {
  color: #f6ad55;
}

.rating-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
}

.business-details {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
}

.detail-item i {
  color: #4a5568;
}

.detail-item a {
  color: #4299e1;
  text-decoration: none;
}

.detail-item a:hover {
  text-decoration: underline;
}

.google-map {
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
}

.google-reviews {
  margin-top: 2rem;
}

.google-reviews h3 {
  margin-bottom: 1.5rem;
  color: #2d3748;
}

.no-reviews {
  text-align: center;
  padding: 3rem;
  background: #F3F4F6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.map-fallback {
  background: #f7fafc;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.view-on-google-maps {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #4299e1;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.view-on-google-maps:hover {
  background: #3182ce;
}

.write-review-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #34D399;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.write-review-button:hover {
  background: #059669;
}

.review-cta {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  background: #F3F4F6;
  border-radius: 8px;
  margin-top: 1rem;
}

.map-alternative {
  background: #F3F4F6;
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-placeholder {
  text-align: center;
  padding: 2rem;
}

.map-placeholder i {
  font-size: 2.5rem;
  color: #4B5563;
  margin-bottom: 1rem;
}

.map-placeholder h3 {
  color: #1F2937;
  margin-bottom: 0.5rem;
}

.map-placeholder p {
  color: #4B5563;
  margin-bottom: 1.5rem;
}

.view-on-maps-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #3B82F6;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.view-on-maps-button:hover {
  background: #2563EB;
}

.map-container {
  margin: 2rem 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.map-container iframe {
  display: block;
  width: 100%;
  border: none;
}

.map-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.map-loading-text {
  font-size: 1.1rem;
  color: #666;
  text-align: center;
  padding: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .map-container iframe {
    height: 300px;
  }
}

.google-branding {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.google-reviews-text {
  color: #5f6368;
  font-size: 1rem;
}

.reviews-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.reviews-header h3 {
  margin: 0;
  color: #5f6368;
}

.view-google-profile {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  background: #1a73e8;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 1rem;
  transition: background-color 0.2s;
}

.view-google-profile:hover {
  background: #1557b0;
  color: white;
  text-decoration: none;
}

.view-google-profile i {
  font-size: 1.2rem;
}

.view-all-reviews {
  margin-left: auto;
  color: #1a73e8;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
}

.view-all-reviews:hover {
  text-decoration: underline;
}

.google-reviews-text {
  color: #202124;
  font-size: 1.1rem;
  font-weight: 500;
}

.rating {
  margin-top: 0.5rem;
}

.star.filled {
  color: #fbbc04;
} 