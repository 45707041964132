.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  z-index: 1000;
  padding: 0;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 60px;
}

.logo {
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 0.25rem 0;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.logo:hover {
  transform: none;
  box-shadow: none;
}

.logo h1 {
  margin: 0;
  font-size: 1.25rem;
  color: #1e3c72;
  font-weight: 600;
  letter-spacing: 0;
}

.logo .tagline {
  font-size: 0.75rem;
  color: #666;
  margin-top: 0.1rem;
  font-weight: 400;
}

.nav-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.nav-menu a {
  text-decoration: none;
  color: #333;
  font-weight: 400;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  padding: 1.2rem 0;
  display: inline-block;
}

.nav-menu a:hover {
  color: #1e3c72;
}

.floating-buttons {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;
}

.floating-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.floating-button.book {
  background: #1e3c72;
}

.floating-button.call {
  background: #28a745;
}

.floating-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.3);
}

.floating-button span {
  font-size: 0.7rem;
  position: absolute;
  white-space: nowrap;
  right: 70px;
  background: rgba(0,0,0,0.8);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
}

.floating-button:hover span {
  opacity: 1;
  transform: translateX(-10px);
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.mobile-menu-button span {
  display: block;
  width: 25px;
  height: 3px;
  background: #333;
  margin: 5px 0;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .header-container {
    padding: 0.5rem 1rem;
    height: auto;
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-right: 0;
  }

  .nav-menu.active {
    display: block;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 1rem;
  }

  .logo {
    padding: 0.5rem 0.75rem;
  }
  
  .logo h1 {
    font-size: 1.3rem;
  }
  
  .logo .tagline {
    font-size: 0.75rem;
  }

  .floating-buttons {
    right: 1rem;
    bottom: 1rem;
  }

  .floating-button {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .floating-button span {
    display: none;
  }
} 