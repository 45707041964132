.hero {
  padding: 8rem 2rem 4rem;
  background: linear-gradient(rgba(30, 60, 114, 0.85), rgba(42, 82, 152, 0.85)), url('../../public/images/shop-front.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  opacity: 0.9;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 3rem;
}

.primary-button, .secondary-button {
  padding: 1rem 2.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.primary-button {
  background: #4CAF50;
  color: white;
}

.secondary-button {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.primary-button:hover, .secondary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.hero-features {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem 2rem;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  font-size: 1.1rem;
}

.feature .icon {
  color: #4CAF50;
  font-weight: bold;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-features {
    flex-direction: column;
    gap: 1rem;
  }

  .feature {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
} 