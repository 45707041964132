.accreditations {
  padding: 4rem 2rem;
  background: #f8f9fa;
}

.accreditations h2 {
  text-align: center;
  color: #1e3c72;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.accreditations-intro {
  text-align: center;
  color: #666;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.accreditations-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
}

.accreditation-card {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  aspect-ratio: 1;
}

.accreditation-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.accreditation-card img {
  width: 100%;
  max-width: 180px;
  height: auto;
  max-height: 120px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.accreditation-name {
  color: #333;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

@media (max-width: 768px) {
  .accreditations {
    padding: 2rem 1rem;
  }

  .accreditations-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    max-width: 400px;
  }

  .accreditation-card {
    padding: 1.5rem;
    width: 100%;
    aspect-ratio: auto;
    min-height: 200px;
  }

  .accreditation-card img {
    width: 100%;
    max-width: 140px;
    height: auto;
    max-height: 100px;
    margin: 0.5rem 0;
  }

  .accreditation-name {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
} 