.reviews-section {
  padding: 5rem 2rem;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.reviews-container {
  max-width: 1200px;
  margin: 0 auto;
}

.reviews-header {
  text-align: center;
  margin-bottom: 4rem;
}

.reviews-title {
  margin-bottom: 2rem;
}

.reviews-header h2 {
  font-size: 2.5rem;
  color: #1e3c72;
  margin-bottom: 1rem;
  font-weight: 700;
}

.reviews-subtitle {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.checkatrade-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 600px;
}

.checkatrade-logo {
  width: 120px;
  height: auto;
}

.rating-summary {
  text-align: left;
}

.stars {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.rating-summary p {
  margin: 0.25rem 0 0 0;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.review-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.review-card:hover {
  transform: translateY(-5px);
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.rating {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e3c72;
}

.review-date {
  color: #666;
  font-size: 0.9rem;
}

.review-service {
  font-size: 1.1rem;
  color: #1e3c72;
  margin-bottom: 1rem;
  font-weight: 600;
}

.review-text {
  color: #444;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 0.9rem;
}

.review-author {
  font-weight: 600;
}

.review-location {
  color: #1e3c72;
}

.view-more-button {
  display: block;
  width: fit-content;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  background: #1e3c72;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.view-more-button:hover {
  background: #2a5298;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .reviews-section {
    padding: 3rem 1rem;
  }

  .reviews-container {
    width: 100%;
  }

  .reviews-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .reviews-title {
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
  }

  .reviews-header h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .reviews-subtitle {
    margin-bottom: 0;
  }

  .checkatrade-badge {
    width: 100%;
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .rating-summary {
    text-align: center;
  }

  .reviews-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 2rem;
  }

  .review-card {
    margin: 0;
    padding: 1.5rem;
  }

  .rating {
    font-size: 2rem;
    color: #ffd700;
  }
} 